import { handleCalendarClick } from '../helpers/callendarClick';
import moment from 'moment';
import { expect } from 'chai';
import sinon from 'sinon';

describe('handleCalendarClick', () => {
  let onParameterChange;
  let dropdown;

  beforeEach(() => {
    onParameterChange = sinon.spy();
    dropdown = { close: sinon.spy() };
  });

  it('should set the dateIntervalOffset and dateIntervalLimit', () => {
    const start = new Date(2023, 9, 5);
    const end = new Date(2023, 9, 10);
    const now = moment().hour(12).minute(0).second(0).millisecond(0);

    handleCalendarClick(start, end, onParameterChange, dropdown);

    const dateIntervalOffset =
      moment(end).dayOfYear() === now.dayOfYear()
        ? 0
        : now.diff(moment(end), 'days');
    const dateIntervalLimit =
      start.getTime() === end.getTime() ? 1 : now.diff(moment(start), 'days');

    expect(onParameterChange.calledOnce).to.be.true;
    expect(
      onParameterChange.calledWith({
        dateIntervalOffset: dateIntervalOffset.toString(),
        dateIntervalLimit: dateIntervalLimit.toString(),
        custom: true,
        filter: '',
      }),
    ).to.be.true;
  }, 1000);
});
