import moment from 'moment';

/**
 * @param {String} value
 * @param {Function} onParameterChange
 * @param {Function} setState
 */

export function handleDropdownClick({ value, onParameterChange, setState }) {
  let dateIntervalOffset;
  let dateIntervalLimit;
  let filter;

  if (value === 'week') {
    filter = 'week';
    const endOfLastWeek = moment().startOf('week').subtract(1, 'days');
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
    dateIntervalOffset = moment().diff(endOfLastWeek, 'days');
    dateIntervalLimit = moment().diff(startOfLastWeek, 'days');
  } else if (value === 'month') {
    filter = 'month';
    const endOfLastMonth = moment().startOf('month').subtract(1, 'days');
    const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
    dateIntervalOffset = moment().diff(endOfLastMonth, 'days');
    dateIntervalLimit = moment().diff(startOfLastMonth, 'days');
  }

  setState({ filter });

  onParameterChange({
    dateIntervalOffset: (dateIntervalOffset || 0).toString(),
    dateIntervalLimit: (dateIntervalLimit || value).toString(),
    custom: false,
  });
}
