import { handleDropdownClick } from '../helpers/dropDownClick';
import { expect } from 'chai';
import sinon from 'sinon';

describe('handleDropdownClick', () => {
  let onParameterChange;
  let setState;

  beforeEach(() => {
    onParameterChange = sinon.spy();
    setState = sinon.spy();
  });

  it('should set the filter to week', () => {
    const value = 'week';
    handleDropdownClick({ value, onParameterChange, setState });

    expect(setState.calledOnce).to.be.true;
    expect(setState.calledWith({ filter: 'week' })).to.be.true;
  });

  it('should set the filter to month', () => {
    const value = 'month';
    handleDropdownClick({ value, onParameterChange, setState });

    expect(setState.calledOnce).to.be.true;
    expect(setState.calledWith({ filter: 'month' })).to.be.true;
  });
});
