import moment from 'moment';

/**
 * @param {Date} start
 * @param {Date} end
 * @param {Function} onParameterChange
 * @param {Object} dropdown
 */

export function handleCalendarClick(start, end, onParameterChange, dropdown) {
  let startDate = start;
  let endDate = end;

  if (endDate && startDate) {
    if (startDate > endDate) {
      startDate = [endDate, (endDate = startDate)][0];
    }

    if (dropdown) dropdown.close();

    startDate.setSeconds(0);
    startDate.setMilliseconds(0);
    endDate.setSeconds(0);
    endDate.setMilliseconds(0);

    const now = moment().hour(12).minute(0).second(0).millisecond(0);
    const dateIntervalOffset =
      moment(endDate).dayOfYear() === now.dayOfYear()
        ? 0
        : now.diff(moment(endDate), 'days');
    const dateIntervalLimit =
      startDate.getTime() === endDate.getTime()
        ? 1
        : now.diff(moment(startDate), 'days');

    onParameterChange({
      dateIntervalOffset: dateIntervalOffset.toString(),
      dateIntervalLimit: dateIntervalLimit.toString(),
      custom: true,
      filter: '',
    });
  }
}
